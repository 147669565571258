import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import FxLayout from '../components/shared/layout/layout';
import uiService from '../components/shared/ui-service';
import { mergePreviewData } from '../services/merge-preview-data';

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        tagline
        siteUrl
      }
    }
  }
`;

export default class IndexPage extends React.Component<any, any> {
  uiService = uiService;
  constructor(props: any) {
    super(props);

    this.state = {
      packageRange: [],
      selectedPackage: -1,
      collectionLayout: true,
      previewPageContext: undefined,
    };

    this.uiService.headerInverted = true;
    this.uiService.headerStickyClass = 'fx-l-header--sticky fx-l-header--dark';
    this.setCollectionLayout = this.setCollectionLayout.bind(this);
  }

  componentDidMount() {
    const IS_BROWSER = typeof window !== 'undefined';
    const staticData = this.props.pageContext;
    if (IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__) {
      this.setState({ previewPageContext: mergePreviewData(staticData, window.__PRISMIC_PREVIEW_DATA__) });
    }
  }

  setCollectionLayout() {
    this.setState({ collectionLayout: !this.state.collectionLayout });
  }

  render() {
    const pageContext = this.state.previewPageContext || this.props.pageContext;
    const seoMeta = pageContext.seoMeta && pageContext.seoMeta.primary;
    const contentPanels = pageContext.contentPanels || [];
    let pageBG;

    if (pageContext.page && pageContext.page.data.background_image) {
      pageBG = {
        backgroundImage: `url(${pageContext.page.data.background_image.url || ''})`,
      };
    }

    return (
      <FxLayout sitemap={pageContext.sitemap}>
        {
          <Helmet>
            <title>{seoMeta && seoMeta.seo_title.text}</title>
            <meta name="keywords" content={seoMeta && seoMeta.seo_keywords.text} />
            <meta name="description" content={seoMeta && seoMeta.seo_description.text} />
            <link
              type="canonical"
              href={`${this.props.data.site.siteMetadata.siteUrl}${pageContext.page && pageContext.page.url}`}
            />
          </Helmet>
        }
        <div
          className="fx-l-legal"
          style={{
            backgroundImage: pageBG && pageBG.backgroundImage ? pageBG.backgroundImage : '',
          }}
        >
          <section className="fx-l-section">
            <div className="fx-container">
              <div className="fx-layout">
                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--4" />
                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--20">
                  <h1 className="fx-l-section-title">{pageContext.page && pageContext.page.data.title.text}</h1>
                  {contentPanels.map((panel: any) => {
                    if (panel.type === 'contentPanel') {
                      return panel.items.map((item: any) => {
                        if (item) {
                          const templates = item.content.document && item.content.document.data.templates;
                          const data = item.content.document && item.content.document.data;

                          if (templates) {
                            if (
                              templates.find((template: any) => {
                                return template.template && template.template.slug === 'information-page';
                              })
                            ) {
                              return (
                                <section key={`${new Date().getMilliseconds()}-${Math.random()}`}>
                                  <div
                                    className="fx-l-section-body"
                                    dangerouslySetInnerHTML={{ __html: data.body.html }}
                                  />
                                </section>
                              );
                            }
                          }
                          return null;
                        }
                        return null;
                      });
                    }
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </FxLayout>
    );
  }
}
